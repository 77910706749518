import React from 'react'
import { MyLayout, NameLink, StyledTextArea } from '../components/common'

const Template = () => {

    return (
        <MyLayout>
            <NameLink to="/" />
            <StyledTextArea>
                <h2>About Me</h2>
                <p>
                I am a Software Engineer with an interest in web development and information security.
                   I write about my experiences developing secure and performant web applications.
                </p>
                <p>
                I work primarily with JavaScript, which I supplement with Java, Go, and more recently, Rust.
                I am very excited about using WebAssembly to improve client side Web Application performance.
                </p>
                <p>
                My interest is information security started with me learning about how the web apps that I was building could be hacked,
                 by trying to do it myself. I am now enjoying learning more about this area, and sharing what I have learned with others. 
                </p>
            </StyledTextArea>

        </MyLayout>
    )
}

export default Template